@import '../../../../../scss/theme-bootstrap';

.product-row {
  display: flex;
  padding-bottom: 24px;
  &:last-child {
    border: none;
    .product-row__info-remove {
      padding: 15px 0 0;
    }
  }
  &__cart-img {
    width: 40px;
    img {
      min-width: 40px;
    }
  }
  &__price {
    font-family: $font--helvetica-neue-bold;
  }
  &__info {
    padding-#{$ldirection}: 25px;
    width: 100%;
    text-align: $ldirection;
    display: flex;
    justify-content: space-between;
    padding-#{$ldirection}: 25px;
    &-remove {
      display: none;
    }
    &-header {
      text-transform: uppercase;
      color: $color-primary-900;
      font-family: $font--helvetica-neue-bold;
      max-width: 250px;
      font-size: 12px;
    }
    &-name {
      width: 150px;
    }
    &-details-size,
    &-details-qty {
      display: inline-block;
    }
    a {
      &.product-row__info-remove {
        text-decoration: underline;
        line-height: get-line-height(12px, 12px);
      }
    }
  }
}
